import React, { useEffect, useState } from "react";
import classnames from "classnames";

import SecureField from "./SecureField";
import SecureFieldIcon from "./SecureFieldIcon";

import "./SecureField.css";
import InputField from "../Form/InputField";
import MonthField from "../Form/MonthField";
import Button from "../Button";

const getUrl = (production: boolean) =>
  production
    ? "https://pay.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js"
    : "https://pay.sandbox.datatrans.com/upp/payment/js/secure-fields-2.0.0.min.js";

const initalInputStyle = "font-size: 100%; border-radius: 0; -webkit-appearance: none; padding: 0";
const initalCssClass = {
  "secure-field": true,
  "secure-field__input ": true,
  "secure-field__base ": true,
  "secure-field__has-actions ": true,
  "secure-field__has-card-icon": true,
  "secure-field__has-error": false,
  "secure-field__is-recognized": false,
};

declare global {
  interface Window {
    SecureFields: any;
  }
}

interface ISecureFields {
  production: boolean;
  config: any;
  callback: (creditCardData: any) => void;
}

export default function SecureFields(props: ISecureFields) {
  const { production, config, callback } = props;
  const [secureFields, setSecureFields] = useState<any>(null);
  const [message, setMessage] = useState<any>(null);
  const [messageCC, setMessageCC] = useState<any>(null);
  const [cardIcon, setCardIcon] = useState("card-empty");
  const [cvvIcon, setCvvIcon] = useState("cvv-empty");
  const [cardContainerClassNames, setCardContainerClassNames] = useState(initalCssClass);
  const [cvvContainerClassNames, setCvvContainerClassNames] = useState(initalCssClass);

  interface IFormState {
    Name: string;
    ExpirationDate: string;
    TransactionId: number;
  }

  const InitialFormState = {
    Name: "",
    ExpirationDate: "",
    TransactionId: 0,
  };
  const [formState, setFormState] = useState<IFormState>(InitialFormState);
  const initialValidState = {
    Name: null,
    ExpirationDate: null,
    TransactionId: null,
  };
  const [validationState, setValidationState] = useState<{ [key: string]: boolean | null }>(
    initialValidState
  );

  const initSecureFields = () => {
    const initalizedSecureFields = new window.SecureFields();
    initalizedSecureFields.initTokenize(config.merchantID, config.fields, config.options);
    setSecureFields(initalizedSecureFields);
  };

  const cleanupSecureFields = () => {
    if (secureFields) {
      window.setTimeout(() => {
        try {
          secureFields.destroy();
          setSecureFields(null);
        } catch (err) {
          console.log("Unmount error", err);
        } // eslint-disable-line no-empty
      }, 1);
    }
  };

  useEffect(() => {
    const scriptSource = getUrl(production);

    if (!secureFields) {
      if (document.querySelector('script[src="' + scriptSource + '"]')) {
        initSecureFields();

        return cleanupSecureFields;
      }

      const script = document.createElement("script");
      script.src = scriptSource;
      script.onload = () => {
        initSecureFields();
      };

      document.body.appendChild(script);

      return cleanupSecureFields;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (secureFields) {
      secureFields.on("ready", () => {
        // Set styles manually as they're inside an iframe and out of the scope of the parent's stylesheets
        secureFields.setStyle("cardNumber", initalInputStyle);
        secureFields.setStyle("cvv", initalInputStyle);
        // secureFields.focus("cardNumber");
      });

      // Set class names and icon when fields change
      secureFields.on("change", (data: any) => {
        let paymentMethod = data.fields.cardNumber.paymentMethod
          ? data.fields.cardNumber.paymentMethod
          : false;

        setMessage(null);
        setMessageCC(null);
        setCardContainerClassNames({
          ...cardContainerClassNames,
          "secure-field__is-recognized": !!paymentMethod,
          "secure-field__has-error": false,
        });
        setCvvContainerClassNames({
          ...cvvContainerClassNames,
          "secure-field__has-error": false,
        });
        setCardIcon(paymentMethod ? "brands/" + paymentMethod : "card-empty");
        setCvvIcon("cvc-empty");
      });

      // Set error icon and class name on validate failure
      secureFields.on("validate", (data: any) => {
        setMessageCC(null);
        if (
          data.fields.cardNumber.paymentMethod !== "VIS" &&
          data.fields.cardNumber.paymentMethod !== "ECA"
        ) {
          console.log("Only Visa and Mastecard are supported");
          
          setCardContainerClassNames({
            ...cardContainerClassNames,
            "secure-field__is-recognized": false,
            "secure-field__has-error": true,
          });
          setCardIcon("card-error");
          setMessageCC("Only Visa and MasterCard are supported");
        }

        if (!data.fields.cardNumber.valid) {
          setCardContainerClassNames({
            ...cardContainerClassNames,
            "secure-field__is-recognized": false,
            "secure-field__has-error": true,
          });
          setCardIcon("card-error");
        }

        if (!data.fields.cvv.valid) {
          setCvvContainerClassNames({
            ...cvvContainerClassNames,
            "secure-field__has-error": true,
          });
          setCvvIcon("cvc-error");
        }

        if (data.hasErrors) {
          setValidationState((previousState) => ({
            ...previousState,
            ...{ TransactionId: false },
          }));
          setFormState((previousState) => ({ ...previousState, ...{ TransactionId: 0 } }));
        }

        validateFields();
      });

      // Show transaction ID on success or transaction error message
      secureFields.on("success", (data: any) => {
        let message = null;
        if (data.transactionId) {
          setValidationState((previousState) => ({ ...previousState, ...{ TransactionId: true } }));
          setFormState((previousState) => ({
            ...previousState,
            ...{ TransactionId: data.transactionId },
          }));

          message = "";
          // (
          //   <pre className={"form-result success"} style={{ marginTop: "20px", fontSize: "1rem" }}>
          //     Data submitted successfully with transaction # {data.transactionId}
          //   </pre>
          // );
        } else if (data.error) {
          setValidationState((previousState) => ({
            ...previousState,
            ...{ TransactionId: false },
          }));
          message = (
            <pre
              className={"form-result " + data.result}
              style={{ marginTop: "20px", fontSize: "1rem" }}
            >
              {data.error}
            </pre>
          );
        }
        setMessage(message);
      });
      secureFields.on("error", function (data: any) {
        console.log("error", data);
        if (data && data?.error !== "form submit already ongoing") {
          setValidationState((previousState) => ({
            ...previousState,
            ...{ TransactionId: false },
          }));
          setCardIcon("card-error");
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secureFields]);

  const handleValueCallback = (obj: { [key: string]: string | boolean }) => {
    setFormState((previousState) => ({ ...previousState, ...obj }));
  };

  const handleValidateCallBack = (obj: { [key: string]: boolean | null }) => {
    setValidationState((previousState) => ({ ...previousState, ...obj }));
  };

  const validateFields = () => {
    let isValid = true;

    let obj: { [key: string]: boolean | null } = {};
    ["Name", "ExpirationDate"].forEach((name) => {
      if (validationState[name] === null) {
        obj[name as keyof typeof obj] = false;
        isValid = false;
      } else if (validationState[name] === false) {
        isValid = false;
      }
    });

    if (!isValid) {
      setValidationState((previousState) => ({ ...previousState, ...obj }));
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <InputField
        type="text"
        label="Name on creditcard"
        name="Name"
        value={formState.Name}
        error={validationState.Name}
        validate={{ min: 1 }}
        errorMessages={{
          min: "Name is required",
        }}
        valueCallback={handleValueCallback}
        validationCallBack={handleValidateCallBack}
      />

      <MonthField
        type="tel"
        label="Expiration date"
        name="ExpirationDate"
        value={formState.ExpirationDate}
        error={validationState.ExpirationDate}
        validate={{ min: 1, expire: true }}
        errorMessages={{
          min: "Expiration date is required",
          yearMin: "Year can not be in the past",
          yearMax: "Year can not be more than 5 years in the future",
          monthMin: "Month can not be in the past",
          monthMax: "Month can not be bigger than 12",
          expire: "Should be a month and a year MM/YYYY",
        }}
        valueCallback={handleValueCallback}
        validationCallBack={handleValidateCallBack}
      />

      <form
        onSubmit={(e) => {
          e.preventDefault();
          secureFields.submit();
        }}
        className="c-form"
      >
        <SecureField
          fieldType="card"
          label="Card number"
          customClass={classnames(cardContainerClassNames)}
          callback={() => secureFields.focus("cardNumber")}
        >
          <SecureFieldIcon fieldType="card" iconType={cardIcon} />
        </SecureField>

        <div className="mt-4 text-red-600 text-sm">{messageCC}</div>
        <div className="mt-4"></div>

        <SecureField
          fieldType="cvv"
          label="CVV"
          customClass={classnames(cvvContainerClassNames)}
          callback={() => secureFields.focus("cvv")}
        >
          <SecureFieldIcon fieldType="cvv" iconType={cvvIcon} />
        </SecureField>

        <div className="flex flex-col gap-4 mt-4">
          <Button
            id="form-submit"
            text="Validate card"
            type="submit"
            onClick={() => {
              secureFields.submit();
            }}
            disabled={
              validationState.Name === true &&
              validationState.ExpirationDate === true &&
              validationState.TransactionId === true
            }
          />
          {message}
        </div>
      </form>

      {validationState.Name === true &&
        validationState.ExpirationDate === true &&
        validationState.TransactionId === true && (
          <Button text="Create reservation" type="button" onClick={() => callback(formState)} />
        )}
    </div>
  );
}
