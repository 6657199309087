import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  clearBooking,
  clearBookingPayment,
  createBookingReservationGroup,
  selectBooking,
  setBookingCreditCardData,
  setBookingRateGroupId,
} from "../features/booking/bookingSlice";
import { selectHotel } from "../features/hotel/hotelSlice";
import { motion } from "framer-motion";
import SecureFields from "../components/SecureFields/SecureFields";
import ContentOverlay from "../components/ContentOverlay";
import ECA from "../assets/images/brands/ECA.svg";
import VIS from "../assets/images/brands/VIS.svg";
import IDEAL from "../assets/images/ideal.png";
import GOOGLEPAY from "../assets/images/google-pay.png";
import APPLEPAY from "../assets/images/apple-pay.png";
import { useNavigate } from "react-router-dom";
import {
  getPaymentCardsAll,
  getPaymentCardsAuthorize,
  getReservationGroup,
} from "../features/booking/bookingApi";
import { IBookingCreditCardData } from "../features/booking/bookingInterface";
import { getUrl, openUrl, returnUrl } from "../helpers/payment";
import { GTMTrackingAddPaymentInfo } from "../helpers/GTMTracking";
import Button from "../components/Button";

export default function Payment() {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const navigate = useNavigate();

  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [ccOpen, setCcOpen] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<string>("");

  useEffect(() => {
    if (booking.Payment.State === "charged") {
      navigate("/success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPaymentState = () => {
    setInterval(() => {
      if (booking.Payment.State === "charged") {
        navigate("/success");
      }
    }, 1000);
  };

  const createNewReservation = async (method: string) => {
    // Set directpayment rate group id to pay with ideal.
    dispatch(setBookingRateGroupId(hotel.DirectPaymentId));
    setOverlayContent(`Creating your reservation.`);
    await new Promise((r) => setTimeout(r, 1000)); // hack for dispatch
    setShowOverlay(true);
    await dispatch(createBookingReservationGroup({ data: null }))
      .then((response) => {
        if (response.payload.PaymentRequestId !== null) {
          setOverlayContent(`Reservation created`);

          // GTM DATALAYER
          GTMTrackingAddPaymentInfo(method);

          setTimeout(() => {
            openUrl(getUrl(method, response.payload.PaymentRequestId));
            setOverlayContent(
              `Finish your payment at our payment service provider. You can close this window.`
            );
            checkPaymentState();
            return false;
          }, 1000);
        } else {
          setOverlayContent(`Something went wrong, please try again.`);
          setTimeout(() => {
            setShowOverlay(false);
          }, 2000);
        }
      })
      .catch((response) => {
        setOverlayContent(`[error:4501] Something went wrong, please try again.`);
        setTimeout(() => {
          setShowOverlay(false);
        }, 2000);
      });
  };

  const directPayment = async (method: string) => {
    if (booking.Payment.PaymentRequestId !== null) {
      getReservationGroup(hotel, booking).then((response) => {
        const payment = response.data.PaymentRequests.find(
          (p: any) => p.Id === booking.Payment.PaymentRequestId
        );

        if (payment) {
          // create new payment
          if (payment.State === "Expired" || payment.State === "Canceled") {
            // Do nothing, there will be created a new reservation down below
            createNewReservation(method);
          } else {
            // Retry paying
            openUrl(getUrl(method, booking.Payment.PaymentRequestId));
            setOverlayContent(`Finish your payment at our payment service provider.`);
            checkPaymentState();
            return false;
          }
        } else {
          createNewReservation(method);
        }
      });
    } else {
      createNewReservation(method);
    }
  };

  const creditCardPayment = async (data: IBookingCreditCardData) => {
    if (data !== undefined) {
      await dispatch(createBookingReservationGroup({ data }))
        .then((response) => {
          // Redirect to direct payment
          if (response.payload.PaymentRequestId !== null) {
            openUrl(getUrl("PaymentCard", response.payload.PaymentRequestId));

            // Redirect to credit card authorization
          } else {
            getPaymentCardsAll(response.payload.PaymentCardId)
              .then((paymentCardResponse) => {
                const paymentCard = paymentCardResponse.data.PaymentCards.find((pc: any) => {
                  return pc.Id === response.payload.PaymentCardId;
                });
                if (paymentCard !== null) {
                  if (paymentCard.AuthorizationState === "Authorizable") {
                    getPaymentCardsAuthorize(hotel, response.payload.PaymentCardId)
                      .then((authorisationResponse) => {
                        // GTM DATALAYER
                        GTMTrackingAddPaymentInfo("Credit Card");

                        if (authorisationResponse.data.State === "Authorized") {
                          navigate("/success");
                        } else if (
                          authorisationResponse.data.State === "Pending" ||
                          authorisationResponse.data.State === "Requested"
                        ) {
                          const url =
                            process.env.REACT_APP_MEWS_APPLICATION_URL +
                            "navigator/card-authorization/detail/" +
                            response.payload.PaymentCardId +
                            "?returnUrl=" +
                            returnUrl();
                          openUrl(url);
                          setOverlayContent(
                            `Finish your autorisation at our payment service provider`
                          );
                          return false;
                        }
                      })
                      .catch(() => {
                        setOverlayContent(`Something went wrong, please try again.`);
                        setShowOverlay(true);
                        setTimeout(() => {
                          setShowOverlay(false);
                        }, 2000);
                      });
                  } else {
                    setOverlayContent(`Creditcard not authorizable`);
                    setShowOverlay(true);
                    setTimeout(() => {
                      setShowOverlay(false);
                    }, 2000);
                  }
                }
              })
              .catch(() => {
                setOverlayContent(`Something went wrong, please try again.`);
                setShowOverlay(true);
                setTimeout(() => {
                  setShowOverlay(false);
                }, 2000);
              });
          }
        })
        .catch(() => {
          setShowOverlay(true);
          setOverlayContent(`Something went wrong, please try again.`);
          setTimeout(() => {
            setShowOverlay(false);
            dispatch(clearBookingPayment());
            dispatch(clearBooking());
          }, 3000);
        });
    } else {
      setOverlayContent(`Something went wrong, please try again.`);
      setTimeout(() => {
        setShowOverlay(false);
      }, 2000);
    }
  };

  const creditCardPaymentSwitch = () => {
    if (booking.Payment.PaymentRequestId !== null) {
      directPayment("PaymentCard");
    } else {
      setCcOpen(!ccOpen);
    }
  };

  interface IFormState {
    Name: string;
    ExpirationDate: string;
    TransactionId: number;
  }
  const getCreditCardData = (creditCardData: IFormState) => {
    // Add zero to month if necessary
    let date = creditCardData.ExpirationDate;
    const dates = date.split("-");
    creditCardData.ExpirationDate =
      dates[0] + "-" + (dates[1].length === 1 ? "0" + dates[1] : dates[1]);

    const data = {
      PaymentGatewayData: creditCardData.TransactionId,
      Expiration: creditCardData.ExpirationDate,
      HolderName: creditCardData.Name,
    };

    setOverlayContent(`Creating your reservation.`);
    setShowOverlay(true);

    dispatch(setBookingCreditCardData(data));
    dispatch(setBookingRateGroupId(""));

    creditCardPayment(data);
  };

  const motionTransition = { type: "tween", stiffness: 100, duration: 0.3 };
  const motionVariants = {
    open: { height: "auto", opacity: 1, y: 0, marginBottom: "1.5rem" },
    closed: { height: 0, opacity: 0, y: "4px", marginBottom: "0" },
  };

  return (
    <>
      <div className="absolute flex flex-col w-full h-full">
        <div className="flex-grow relative w-full">
          <motion.div
            initial={{ opacity: 0, y: "-12px" }}
            animate={{ opacity: 1, y: "0px" }}
            exit={{ opacity: 0, y: "-12px" }}
            className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
          >
            <div className="flex flex-col">
              <div className="text-h3">Payment methods</div>
              {hotel.PaymentGateway && hotel.PaymentGateway.PaymentGatewayType === "PciProxy" && (
                <div className="bg-white bg-opacity-50 rounded-md p-2 mt-4">
                  <motion.div
                    transition={motionTransition}
                    variants={motionVariants}
                    animate={ccOpen ? "open" : "closed"}
                    className="overflow-hidden"
                  >
                    <div className="text-h4">Authorize your creditcard</div>

                    <div className="mt-2">
                      <SecureFields
                        production={process.env.REACT_APP_ENV === "production"}
                        config={{
                          merchantID: hotel.PaymentGateway.PublicKey,
                          fields: {
                            cardNumber: {
                              placeholderElementId: "card-number",
                              inputType: "tel",
                            },
                            cvv: {
                              placeholderElementId: "cvv-number",
                              inputType: "tel",
                            },
                          },
                          options: {},
                        }}
                        callback={getCreditCardData}
                      />
                    </div>
                    <div className="mt-4 text-center">
                      <span>Your card will be charged 48 hours prior to arrival day</span>
                      <span>Secured with Mews Payments via PCI Proxy</span>
                      <div className="flex justify-center gap-2 mt-2">
                        <img src={VIS} alt="Visa" />
                        <img src={ECA} alt="Master Card" />
                      </div>
                    </div>
                  </motion.div>

                  <motion.div
                    transition={motionTransition}
                    variants={motionVariants}
                    animate={ccOpen ? "closed" : "open"}
                    className="overflow-hidden"
                  >
                  <div className="flex gap-2 my-2">
                    <span>We accept</span>
                    <img src={VIS} alt="Visa" />
                    <img src={ECA} alt="Master Card" />
                  </div>
                  <Button text="Pay with Credit Card" onClick={() => creditCardPaymentSwitch()} />
                  </motion.div>
                </div>
              )}
            </div>

            {hotel.DirectPaymentId.length > 15 && (
              <div className="bg-white bg-opacity-50 rounded-md p-2 mt-6">
                <div className="flex gap-2 my-2">
                  <span>Or pay with</span>
                  <img src={IDEAL} alt="Ideal" className="h-6" />
                  <img src={GOOGLEPAY} alt="Google pay" className="h-6" />
                  <img src={APPLEPAY} alt="Apple pay" className="h-6" />
                </div>
                <Button text="Pay directly" onClick={() => directPayment("Ideal")} />
              </div>
            )}
          </motion.div>
        </div>
      </div>
      <ContentOverlay show={showOverlay} content={overlayContent} />
    </>
  );
}
