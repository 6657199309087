import { useAppSelector, useAppDispatch } from "../app/hooks";
import {
  selectBooking,
  removeBookingProducts,
  removeBookingVoucherCode,
  getBookingReservationPrice,
  removeBookingRoom,
} from "../features/booking/bookingSlice";
import { formatCurrency } from "../helpers/currency";
import { findProduct } from "../helpers/reservation";
import { selectHotel } from "../features/hotel/hotelSlice";
import { findRoomCategory } from "../helpers/roomCategory";
import { IBookingProduct, IBookingState } from "../features/booking/bookingInterface";
import { getPriceByRate } from "../helpers/reservation";
import { useEffect, useState } from "react";
import VoucherField from "../components/Form/VoucherField";
import { motion } from "framer-motion";
import { dateToMonthSmall } from "../helpers/date";
import RemoveSvg from "../components/Svg/RemoveSVG";
import {
  GTMTrackingRemoveAccommodationFromCart,
  GTMTrackingRemoveProductFromCart,
} from "../helpers/GTMTracking";

function Summary() {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const [voucherCode, setVoucherCode] = useState<IBookingState["VoucherCode"]>(booking.VoucherCode);
  const [reservations, setReservations] = useState<any[]>([]);

  const handleValueCallback = (obj: { [key: string]: string | boolean }) => {
    if (obj.VoucherCode !== undefined) {
      setVoucherCode(obj.VoucherCode as string);
    }
  };

  const removeRoom = (number: number) => {
    // GTM DATALAYER
    GTMTrackingRemoveAccommodationFromCart(number);

    dispatch(removeBookingRoom(number));
  };

  const removeProduct = (Id: IBookingProduct["Id"]) => {
    // GTM DATALAYER
    GTMTrackingRemoveProductFromCart(Id);

    dispatch(removeBookingProducts(Id));
  };

  const removeVoucherCode = () => {
    setVoucherCode(null);
    dispatch(removeBookingVoucherCode());
    dispatch(getBookingReservationPrice());
  };

  useEffect(() => {
    let reservations: any = [];
    booking.Reservations.forEach((reservation, i) => {
      const found = reservations.some((r: any) => {
        return (
          reservation.RoomCategoryId === r.RoomCategoryId && reservation.AdultCount === r.AdultCount
        );
      });
      if (!found) {
        let array: any = { ...reservation };
        array.number = i;
        array.count = 1;
        reservations.push(array);
      } else {
        const array = reservations.find((r: any) => {
          return (
            reservation.RoomCategoryId === r.RoomCategoryId &&
            reservation.AdultCount === r.AdultCount
          );
        });
        array.count++;
      }
    });
    setReservations(reservations);
  }, [booking.Reservations]);

  return (
    <motion.div
      initial={{ opacity: 0, y: "-12px" }}
      animate={{ opacity: 1, y: "0px" }}
      exit={{ opacity: 0, y: "-12px" }}
      className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
    >
      <div className="text-h3">Accommodation</div>
      <div className="text-h4">
        {dateToMonthSmall(booking.StartUtc)} - {dateToMonthSmall(booking.EndUtc)} (
        {booking.NightCount} night{booking.NightCount === 1 ? "" : "s"}
        {", "}
        {booking.PeopleCount} traveller{booking.PeopleCount === 1 ? "" : "s"})
      </div>

      <div className="flex flex-col gap-2">
        {reservations &&
          reservations.map(function (reservation, i) {
            const roomCategory = findRoomCategory(reservation.RoomCategoryId);
            const roomCategoryName = roomCategory?.Name[hotel.DefaultLanguageCode];
            const pricing = getPriceByRate(reservation);
            const rate = hotel.Rates.find((r) => r.Id === reservation.RateId);
            const competitorPercent = hotel.CompetitorPriceRelativeAdjustment * 100 - 100;

            return (
              <div className="flex gap-4 mt-2 bg-white bg-opacity-50 p-2 rounded-md" key={i}>
                {pricing && (
                  <>
                    <div className="hidden no-mobile:block max-w-[150px]">
                      <img
                        className="rounded-md object-cover leading-none w-full"
                        src={`${hotel.ImageBaseUrl}/${roomCategory?.ImageIds[0]}?Mode=0&Height=300&Width=300`}
                        alt="Accommodation"
                      />
                    </div>
                    <div className="flex-grow min-w-[250px] flex flex-col">
                      <div className="text-h4">
                        {reservation.count} {roomCategoryName}
                        {reservation.count === 1 ? "" : "s"}
                        {", "}
                        {reservation.AdultCount} traveller
                        {reservation.AdultCount === 1 ? "" : "s"}
                      </div>

                      <div className="opacity-75 text-medium">
                        {parseInt("" + competitorPercent)}% Direct Booking Discount
                        {pricing.MaxPrice.Total[hotel.DefaultCurrencyCode] >
                          pricing.Price.Total[hotel.DefaultCurrencyCode] && (
                          <div>+ {rate?.Name[hotel.DefaultLanguageCode]}</div>
                        )}
                      </div>

                      <div className="flex justify-between opacity-75 text-medium">
                        <span>Per night</span>
                        <span>
                          {formatCurrency(pricing.Price.AveragePerNight[hotel.DefaultCurrencyCode])}
                        </span>
                      </div>

                      <div className="flex justify-between text-h5 flex-grow items-end">
                        <span>Total</span>
                        <span>
                          {formatCurrency(pricing.Price.Total[hotel.DefaultCurrencyCode] * reservation.count)}
                        </span>
                      </div>
                    </div>

                    {booking.Reservations.length > 1 && (
                      <div>
                        <div
                          className="flex justify-center items-center h-full w-full"
                          onClick={() => removeRoom(reservation.number)}
                        >
                          <RemoveSvg />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            );
          })}
      </div>

      {booking.Products && booking.Products.length > 0 && (
        <>
          <div className="text-h3 mt-4">Extras</div>
          <div className="flex flex-col gap-4">
            {booking.Products.map(function (product, i) {
              const hotelProduct = findProduct(product.Id);
              return (
                <div className="flex gap-4 mt-2 bg-white bg-opacity-50 p-2 rounded-md" key={i}>
                  <div className="hidden no-mobile:block max-w-[150px]">
                    <img
                      className="rounded-md object-cover leading-none h-full w-full"
                      src={`${hotel.ImageBaseUrl}/${hotelProduct?.ImageId}?Mode=0&Height=300&Width=300`}
                      alt="Extra"
                    />
                  </div>
                  <div className="flex-grow min-w-[250px] flex flex-col">
                    <div className="text-h4">
                      {hotelProduct !== undefined && hotelProduct.Name[hotel.DefaultLanguageCode]}
                    </div>

                    <div className="opacity-75 text-medium">
                      {booking.NightCount} night{booking.NightCount === 1 ? "" : "s"}
                      {", "}
                      {booking.PeopleCount} traveller{booking.PeopleCount === 1 ? "" : "s"}
                    </div>

                    <div className="flex justify-between opacity-75 text-medium">
                      <span>Per person, per night</span>
                      <span>{formatCurrency(product.Price)}</span>
                    </div>

                    <div className="flex justify-between text-h5 flex-grow items-end">
                      <span>Total</span>
                      <span>
                        {formatCurrency(product.Price * booking.PeopleCount * booking.NightCount)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div
                      className="flex justify-center items-center h-full w-full"
                      onClick={() => removeProduct(product.Id)}
                    >
                      <RemoveSvg />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      <div className="flex justify-between text-h2 mt-6">
        <span>Total</span>
        <span>{formatCurrency(booking.ReservationPrice.GrossValue)}</span>
      </div>

      <div className="flex justify-between gap-2 opacity-75">
        <div>Included in total:</div>
        <div>
          {Object.values(booking.ReservationPrice.Products).map((item, i) => {
            return (
              <div className="flex justify-between gap-2" key={i}>
                <span>{item[hotel.DefaultLanguageCode]}</span>
                <span>{formatCurrency(Number(item["GrossValue"]))}</span>
              </div>
            );
          })}

          <div className="flex justify-between gap-2">
            <span>Vat {hotel.HotelCode === "CPH" ? "25%" : "9%"}</span>
            <span>{formatCurrency(booking.ReservationPrice.TaxValue)}</span>
          </div>
        </div>
      </div>

      <div className="my-8 border-t border-gray-500"></div>

      {booking.VoucherCode !== null && (
        <div className="w-full">
          <div>Voucher code</div>
          <div
            className={`flex items-center gap-2 bg-white rounded-md p-2 border border-green-600 mt-1`}
          >
            <div className="flex-grow w-full">{booking.VoucherCode}</div>
            <div
              onClick={() => {
                removeVoucherCode();
              }}
            >
              <RemoveSvg />
            </div>
          </div>
        </div>
      )}

      {booking.VoucherCode === null && (
        <div className="w-full">
          <VoucherField
            type="text"
            label="Voucher code"
            name="VoucherCode"
            value={voucherCode}
            error={true}
            validate={{}}
            errorMessages={{}}
            valueCallback={handleValueCallback}
            validationCallBack={() => {}}
          />
        </div>
      )}
    </motion.div>
  );
}

export default Summary;
