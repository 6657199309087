import { store } from "../app/store";
import {
  IBookingReservation,
  IBookingRoom,
  IBookingState,
} from "../features/booking/bookingInterface";
import {
  clearBookingReservations,
  clearBookingRooms,
  setBookingError,
  addBookingRoom,
  toggleBookingCartCollapsed,
  addBookingReservations,
  setOpenReservation,
} from "../features/booking/bookingSlice";
import {
  IHotelRoomCategory,
  IHotelRoomCategoryAvailability,
} from "../features/hotel/hotelInterface";

/**
 * Find room category from RoomCategories
 * @param roomCategories
 * @param Id
 * @returns IHotelRoomCategory | undefined
 */
export const findRoomCategory = (Id: IHotelRoomCategory["Id"]): IHotelRoomCategory | undefined => {
  const { hotel } = store.getState();
  return hotel.RoomCategories.find((c) => c.Id === Id);
};

export const getRoomCategoryName = (Id: IHotelRoomCategory["Id"]): string => {
  const { hotel } = store.getState();
  const category = hotel.RoomCategories.find((c) => c.Id === Id);
  if(category !== undefined) return category.Name[hotel.DefaultLanguageCode];
  return '';
};

export const findRoomCategoryByName = (Name: string): IHotelRoomCategory | undefined => {
  const { hotel } = store.getState();
  return hotel.RoomCategories.find((c) => c.Name[hotel.DefaultLanguageCode] === Name);
};

export const filterRoomCategoryAvailability = (RoomCategoryId: IHotelRoomCategory["Id"]) => {
  const { hotel } = store.getState();
  return hotel.RoomCategoryAvailabilities.filter((rca) => rca.RoomCategoryId === RoomCategoryId);
};

export const findRoomCategoryAvailability = (RoomCategoryId: IHotelRoomCategory["Id"]) => {
  const { hotel } = store.getState();
  return hotel.RoomCategoryAvailabilities.find((rca) => rca.RoomCategoryId === RoomCategoryId);
};

export const findRoomOccupancyAvailability = (
  RoomCategoryAvailability: IHotelRoomCategoryAvailability,
  AdultCount: IBookingState["AdultCount"],
  ChildCount: IBookingState["ChildCount"]
) => {
  return RoomCategoryAvailability.RoomOccupancyAvailabilities.find(
    (roa) => roa.AdultCount === AdultCount && roa.ChildCount === ChildCount
  );
};

export const getBookedRoomCount = (
  RoomCategoryId: IHotelRoomCategory["Id"],
  SkipBookedRoomCount = false
) => {
  if (SkipBookedRoomCount) return 0;
  const { booking } = store.getState();
  return booking.Reservations.filter((r) => r.RoomCategoryId === RoomCategoryId).length;
};

export const getAvailableRoomCount = (
  RoomCategoryId: IBookingRoom["RoomCategoryId"],
  SkipBookedRoomCount = false
) => {
  const rca = findRoomCategoryAvailability(RoomCategoryId);
  const bookedRoomCount = getBookedRoomCount(RoomCategoryId, SkipBookedRoomCount);
  return rca && rca.AvailableRoomCount > bookedRoomCount;
};

export const getRoomCategoryIdWithAvailability = (SkipBookedRoomCount = false) => {
  const { hotel, booking } = store.getState();
  if (booking.Error !== null) store.dispatch(setBookingError(null));
  for (let index = 0; index < hotel.RoomCategories.length; index++) {
    const RoomCategoryId = hotel.RoomCategories[index].Id;
    if (getAvailableRoomCount(RoomCategoryId, SkipBookedRoomCount)) {
      return RoomCategoryId;
    }
  }
  return "";
};

export const addRoom = ({
  SkipBookedRoomCount = false,
  ClearRooms = false,
}: {
  SkipBookedRoomCount?: boolean;
  ClearRooms?: boolean;
}): void => {
  const { booking } = store.getState();
  if (booking.Error !== null) store.dispatch(setBookingError(null));
  store.dispatch(clearBookingReservations());
  if (ClearRooms) store.dispatch(clearBookingRooms());

  const RoomCategoryId = getRoomCategoryIdWithAvailability(SkipBookedRoomCount);

  // No availability
  if (RoomCategoryId === "") {
    store.dispatch(toggleBookingCartCollapsed(false));
    store.dispatch(setBookingError(booking.ErrorText));
  }
  // Availability
  else {
    store.dispatch(
      addBookingRoom({
        RoomCategoryId,
        AdultCount: booking.AdultCount,
        ChildCount: booking.ChildCount,
        Open: true,
      })
    );
  }
};

/**
 * Refresh reservations based on rooms
 */
export const refreshReservations = (): void => {
  const { booking, hotel } = store.getState();
  store.dispatch(clearBookingReservations());

  let _reservations: IBookingReservation[] = [];
  let _open = 0;

  booking.Rooms.forEach((room: IBookingRoom, i) => {
    const rca = findRoomCategoryAvailability(room.RoomCategoryId);
    if (rca) {
      const roomOccupancyAvailability = findRoomOccupancyAvailability(
        rca,
        room.AdultCount,
        room.ChildCount
      );
      if (roomOccupancyAvailability) {
        let pricing = roomOccupancyAvailability.Pricing[0];
        if (booking.RateGroupId !== "") {
          const rates = hotel.Rates.filter((r) => r.RateGroupId === booking.RateGroupId);
          const price = roomOccupancyAvailability.Pricing.find((price) =>
            rates.map((r) => r.Id).includes(price.RateId)
          );
          if (price) {
            pricing = price;
          }
        }
        const reservation: IBookingReservation = {
          RoomCategoryId: room.RoomCategoryId,
          StartUtc: booking.StartUtc as string,
          EndUtc: booking.EndUtc as string,
          RateId: pricing.RateId,
          AdultCount: room.AdultCount,
          ChildCount: room.ChildCount,
          ProductIds: booking.Products.map((p) => p.Id),
          VoucherCode: booking.VoucherCode,
        };
        _reservations.push(reservation);
        if (room.Open) _open = i;
      }
    }
  });
  store.dispatch(addBookingReservations(_reservations));
  store.dispatch(setOpenReservation(_open));
};
