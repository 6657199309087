import Loader from "./Loader";

interface IContentOverlay {
  show: boolean;
  content: string;
}

export default function ContentOverlay(props: IContentOverlay) {
  const { show, content } = props;
  return (
    <div className={`fixed inset-0 ${show ? "" : "hidden"}`}>
      <div className="flex flex-col w-full h-full items-center justify-center bg-black bg-opacity-50">
        <Loader classes="ml-6"/>
        <div className="text-white text-center mt-4">{content}</div>
      </div>
    </div>
  );
}
